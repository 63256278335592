<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="名称" prop="moduleName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.moduleName"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button>
                <!-- <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                > -->
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    prop="id"
                    label="#"
                    width="55"
                ></el-table-column>
                <el-table-column prop="moduleName" label="名称">
                </el-table-column>
                
                <el-table-column
                    prop="createTime"
                    label="时间"
                ></el-table-column>

                <el-table-column label="操作" fixed="right" width="170">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="copyClick(scope.row)"
                            >复制地址</el-button
                        >
                        <!-- <el-button type="text" @click="deleteClick(scope.row)"
                            >删除</el-button
                        > -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getDataScreenManagementListApi, getDataScreenManagementDeleteApi } from '@/api/dataScreenManagement'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getDataScreenManagementListFun()
    },
    methods: {
        async getDataScreenManagementListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr:'id desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await getDataScreenManagementListApi(p)
            console.log('res', res);
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            // console.log('onSubmit', this.formInline);
            this.getDataScreenManagementListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getDataScreenManagementListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getDataScreenManagementListFun(parm.currentPage, parm.pageSize)
        },
        // 新增模板
        addReport() {
            this.$router.push({
                path: '/dataScreenManagement/add',
            })
        },
        // 复制地址
        copyClick(row){
            var url = 'https://v2.wenjuan.ak.sdcom.gov.cn/api/screenModule/%7Bid%7D?id='+row.id
            var input = document.createElement("input"); // 创建input对象
            input.value = url; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success('复制成功！');
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/dataScreenManagement/add',
                query: {
                    id: row.id,
                },
            })
        },
        // 删除
        deleteClick(row) {
            console.log('row', row)
            var ids = [row.id]
            this.$alert(
                '这确定要删除' + row.moduleName + '吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getDataScreenManagementDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getDataScreenManagementListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 批量删除
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getDataScreenManagementDeleteApi(params)

                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getDataScreenManagementListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
